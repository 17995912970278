import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CalltoactionComponent } from './calltoaction/calltoaction.component';
import { AccordionComponent } from './accordion/accordion.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Routes, RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HeaderMobileNavComponent } from './header-mobile-nav/header-mobile-nav.component';
import { CommonTestimonialSectionComponent } from './common-testimonial-section/common-testimonial-section.component';
import { RequestCallbackComponent } from './request-callback/request-callback.component';
import { CommonAppointmentComponent } from './common-appointment/common-appointment.component';
import { ModalComponent } from './modal/modal.component';
import { CommonPressMediaComponent } from './common-press-media/common-press-media.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CalltoactionComponent,
    AccordionComponent,
    SidebarComponent,
    HeaderMobileNavComponent,
    CommonTestimonialSectionComponent,
    RequestCallbackComponent,
    CommonAppointmentComponent,
    ModalComponent,
    CommonPressMediaComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    RouterModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CalltoactionComponent,
    AccordionComponent,
    SidebarComponent,
    LazyLoadImageModule,
    CommonTestimonialSectionComponent,
    RequestCallbackComponent,
    CommonAppointmentComponent,
    CommonPressMediaComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
