import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private showFooter = new BehaviorSubject<boolean>(true);
  showFooter$ = this.showFooter.asObservable();
  toggleFooter(show: boolean) {
    this.showFooter.next(show);
  }
}