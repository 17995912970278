import{ Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import{ HttpClient, HttpClientModule } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class resultapi{
   backendUrl = environment.backendbaseUrl;
    constructor(private httpclient: HttpClient){}
    results(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "results");
    }

    resultcategory(id:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "result-category/" + id);
    }

    resultdetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "result-details/" + name);
    }

    pressmediadetails(name): Observable<any>{
        return this.httpclient.get( this.backendUrl + "pressmediadetail/"+name);
    }
} 