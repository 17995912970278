import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { commonapi } from '../../classes/common/common-api';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush, 
})

export class ErrorpageComponent implements OnInit {

  /* Start of default lazyload */
  defaultImage = 'assets/images/defaultImage.jpeg';
  image144 = 'assets/images/booanappointmentbg.webp';

  servelUrl = environment.baseUrl;
  backendUrl = environment.backendbaseUrl;
  backendimageUrl = environment.backendimageUrl;

  listseo: any;
  canonical: any;
  canonicaltag: any;

  constructor(private _commonapi: commonapi, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef, private sanitizer: DomSanitizer, private meta: Meta, private title: Title, @Inject(DOCUMENT) private dom) { }

  ngOnInit(): void {
  }

  getseolist() {
    this._commonapi.seo(this.router.url).subscribe(
      data => {
        this.listseo = data.data;
        this.canonicaltag = data.data.canonical;

        this.title.setTitle(this.listseo?.title_tag);
        this.meta.updateTag({ name: 'description', content: this.listseo?.description_tag });
        this.meta.updateTag({ name: 'keywords', content: this.listseo?.keyword_tag });
        this.updateCanonicalUrl(this.servelUrl + this.router.url);
        const fullUrl = this.servelUrl + this.router.url;
        this.updateCanonicalUrl(fullUrl);
        this.updateAlternateUrl(fullUrl);  // Add alternate link

        this.meta.updateTag({ property: 'og:title', content: this.listseo?.title_tag });
        this.meta.updateTag({ property: 'og:image', content: 'https://www.dadumedicalcentre.com/assets/images/facebook-preview.jpg?v=1' });
        this.meta.updateTag({ property: 'og:url', content: this.servelUrl + this.router.url});
        this.meta.updateTag({ property: 'og:site_name', content: 'Dadu Medical Centre' });
        this.meta.updateTag({ property: 'og:type', content: 'Website' });
        this.meta.updateTag({ property: 'og:description', content: this.listseo?.description_tag });

        this.meta.updateTag({ property: 'twitter:card', content: '' });
        this.meta.updateTag({ property: 'twitter:title', content: this.listseo?.title_tag });
        this.meta.updateTag({ property: 'twitter:image', content: 'https://www.dadumedicalcentre.com/assets/images/facebook-preview.jpg?v=1' });
        this.meta.updateTag({ property: 'twitter:site', content: 'Dadu Medical Centre' });
        this.meta.updateTag({ property: 'twitter:description', content: this.listseo?.description_tag });
        this.cdr.markForCheck();
      }
    );
  }

  
  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical')
    element.setAttribute('href', url)
  }

  updateAlternateUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate'][hreflang='en-in']`) || null;
    if (element == null) {
        element = this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
    }
    element.setAttribute('rel', 'alternate');
    element.setAttribute('hreflang', 'en-in');
    element.setAttribute('href', url);
}

}
