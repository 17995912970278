import{ Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import{ HttpClient, HttpClientModule } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class commonapi{
   backendUrl = environment.backendbaseUrl;

    constructor(private httpclient: HttpClient){}

    googlecaptcha():Observable<any>{
      return this.httpclient.get( this.backendUrl + "googlecaptcha");
    }

    homeinflutestimonials(): Observable<any>{
      return this.httpclient.get( this.backendUrl + "homeinflutestimonials");
    }

    influtestimonials(): Observable<any>{
      return this.httpclient.get( this.backendUrl + "influtestimonials");
    }

    testimonials(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "testimonials");
    }
     testimonialvideos(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "testimonialvideos");
    }
    leavefeedbacklist(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "leavefeedbacklist");
    }
    patienttestimonials(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "patienttestimonials");
    }
    cardpatient(): Observable<any> {
        return this.httpclient.get( this.backendUrl + "cardpatient");
        }

    gallery(): Observable<any> {
        return this.httpclient.get( this.backendUrl + "gallery");
       }

       gallerycat(): Observable<any> {
        return this.httpclient.get( this.backendUrl + "gallerycat");
       }

    gallerynew(name:any): Observable<any> {
    return this.httpclient.get( this.backendUrl + "gallerynew/"+ name);
    }

    paymentdata(name:any): Observable<any> {
    return this.httpclient.get( this.backendUrl+'paymentdata/'+ name );
    }

    videos(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "videos");
    }

    videocategory(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "video-category/" + name);
    }

    videodetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "video-details/" + name);
    }

    service(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "service");
    }

    conditions(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "conditions");
    }

    exclusive(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "exclusive");
    }

    servicecategory(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "service-category/" + name);
    }

    servicedetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "service-details/" + name);
    }

    servicefaq(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "service-faq/" + name);
    }

    technology(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "technology");
    }

    technologydetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "technology-detail/" + name);
    }

    doctordetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "doctor-detail/" + name);
    }

    blog(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "blog");
    }

    blogdetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "blog-detail/" + name);
    }

    blogpost(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "blog-post");
    }

    seo(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "seo" + name);
    }

    careerslist(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "careerlist");
    }

    careersdetails(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "careerdetails/" + name);
    }

    getleavefeedback(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "leavename/" + name);
    }

    resultcategorydetails(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "result-category-details/" + name);
    }

    videocategorydetail(name:any): Observable<any>{
        return this.httpclient.get( this.backendUrl + "video-category-details/" + name);
    }

    country(): Observable<any>{
        return this.httpclient.get( this.backendUrl + "countrylist");
    }



}
