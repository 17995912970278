// import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WINDOW } from "../../classes/windows.service";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  host: {
    '(document:click)': 'onClick($event)',
  },
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush, 
})

export class HeaderComponent implements OnInit {

  servelUrl = environment.baseUrl;
  scroll: boolean = false;
  displayIframe = false;
  deviceInfo: any;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;

  // fullbodychecker: boolean = true;

  constructor(
    private renderer: Renderer2,
    private headerService: HeaderService,
    private meta: Meta,
    private _eref: ElementRef,
    @Inject(WINDOW) private window: Window, 
    private cdr: ChangeDetectorRef,
    //  @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
  ) {
    // this.checkUserAgent();
    this.epicFunction();
    this.headerService.toggleHeader(true);
  }

  // checkUserAgent(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const targetUserAgentString = 'moto g power (2022)';
  //     const userAgent = navigator.userAgent;
  //     this.fullbodychecker = userAgent.indexOf(targetUserAgentString) !== -1;
  //     console.log(this.fullbodychecker, "fullbody checker");
  //   }
  // }

  showHeader$ = this.headerService.showHeader$;

  ngOnInit() {}

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  onClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.doSomething();
    }
  }

  isShown: boolean = true;

  doSomething() {
    const dsdsdsElement = this.document.getElementById('dsdsds');
    const hamburgerElements = this.document.querySelectorAll('.hamburger');
    const siteNavElement = this.document.getElementById('site-nav');
    const mastheadElement = this.document.getElementById('masthead');

    dsdsdsElement?.classList.remove('hdrtgl');
    hamburgerElements.forEach(el => el.classList.remove('is-active'));
    siteNavElement?.classList.remove('is-active');
    mastheadElement?.classList.remove('is-active');
    this.displayIframe = true;
    this.cdr.markForCheck();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    const headerElement = this.document.getElementById('header');
    if (headerElement) {
      if (this.window.pageYOffset > 10) {
        this.renderer.addClass(headerElement, 'scroll');
      } else {
        this.renderer.removeClass(headerElement, 'scroll');
      }
    }
  }

  menuclick(): boolean {
    const menuToggle = this.document.getElementById('menu-toggle') as HTMLInputElement;
    const hamburgerElements = this.document.querySelectorAll('.hamburger');
    const siteNavElement = this.document.getElementById('site-nav');
    const mastheadElement = this.document.getElementById('masthead');
    const dsdsdsElement = this.document.getElementById('dsdsds');
    const headerElement = this.document.querySelector('header');
  
    // Ensure menuToggle is not null before accessing its properties
    if (menuToggle) {
      menuToggle.checked = false;
    }
  
    // Toggle classes on elements if they exist
    hamburgerElements.forEach(el => el.classList.toggle('is-active'));
    if (siteNavElement) {
      siteNavElement.classList.toggle('is-active');
    }
    if (mastheadElement) {
      mastheadElement.classList.toggle('is-active');
      mastheadElement.style.display = 'block';
    }
    if (dsdsdsElement) {
      dsdsdsElement.classList.toggle('hdrtgl');
    }
    if (headerElement) {
      headerElement.classList.toggle('scrollbar-top');
    }
  
    this.displayIframe = true;
    return false;
  }

  logoclick() {
    this.document.querySelector('.homeheader')?.classList.remove('scrollbar-top');
    this.document.querySelector('.desktop_header')?.classList.remove('hdrtgl');
    this.document.getElementById('site-nav')?.classList.remove('is-active');
    this.document.getElementById('masthead')?.classList.remove('is-active', 'scrollbar-top');
    this.document.getElementById('hamburger-id')?.classList.remove('is-active');
  }

  mobilesec() {
    this.document.querySelector('.pushNav')?.classList.remove('isOpen');
  }

  preventDefault(event: Event) {
    event.preventDefault();
  }

  onclicklink(event: Event) {
    const siteNavElement = this._eref.nativeElement.querySelector("#site-nav");
    const mastheadElement = this._eref.nativeElement.querySelector("#masthead");
    const headerElement = this._eref.nativeElement.querySelector("#header");

    siteNavElement?.classList.remove('is-active');
    mastheadElement?.classList.remove('is-active', 'scrollbar-top');
    headerElement?.classList.remove('scrollbar-top');
    this.displayIframe = true;
    event.preventDefault();
  }
}
