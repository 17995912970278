<!-- <div *ngIf="!fullbodychecker">  -->
<!-- Start of Main Header -->
<header id="header" class="header homeheader" *ngIf="showHeader$ | async">
  <!-- Start of Desktop Header -->
  <div class="hero d-none d-sm-none d-md-none d-lg-block d-xl-block" *ngIf="isDesktopDevice || isTablet">
    <header id="masthead" role="banner">
      <div class="container-fluid">
        <nav id="site-nav" class="" role="navigation">
          <!-- Start of Tab Menu -->
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#tab1" routerlinkactive="active-link" class="nav-link navbar-default active"
                data-toggle="tab">Treatments</a>
            </li>
            <li class="nav-item">
              <a href="#tab2" routerlinkactive="active-link" class="nav-link navbar-default"
                data-toggle="tab">Conditions</a>
            </li>
            <li class="nav-item">
              <a href="#tab3" routerlinkactive="active-link" class="nav-link navbar-default" data-toggle="tab">Exclusive
                Treatments</a>
            </li>
            <li class="nav-item">
              <a href="#tab4" routerlinkactive="active-link" class="nav-link navbar-default" data-toggle="tab">About</a>
            </li>
            <li class="nav-item">
              <a href="#tab5" routerlinkactive="active-link" class="nav-link navbar-default"
                data-toggle="tab">Testimonials</a>
            </li>
            <li class="nav-item">
              <a href="#tab6" routerlinkactive="active-link" class="nav-link navbar-default"
                data-toggle="tab">Results</a>
            </li>
            <li class="nav-item">
              <a href="#tab7" routerlinkactive="active-link" class="nav-link navbar-default"
                data-toggle="tab">Videos</a>
            </li>
            <li class="nav-item">
              <a routerLink="/blogs" routerlinkactive="active-link" class="nav-link navbar-default hamburgermenu"
                (click)="menuclick()">Blogs</a>
            </li>
            <li class="nav-item">
              <a routerLink="/careers" routerlinkactive="active-link" class="nav-link navbar-default hamburgermenu"
                (click)="menuclick()">Careers</a>
            </li>
            <li class="nav-item">
              <a routerLink="/contact-us" routerlinkactive="active-link" class="nav-link navbar-default hamburgermenu"
                (click)="menuclick()">Contact Us</a>
            </li>
          </ul>

          <div class="tab-content cstmScroll" *ngIf="displayIframe">
            <!-- Start of tab1 -->
            <div id="tab1" class="tab-pane active tab-box">
              <!-- Start of headerrow -->
              <div class="headerrow">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-pimple"
                        routerlinkactive="active-link">Acne / Pimple <i class="fa fa-angle-right" aria-label="right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne-check-peel"
                          routerlinkactive="active-link">DMC-Acne Check Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-black-magic-peel"
                          routerlinkactive="active-link">DMC-Black Magic Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne"
                          routerlinkactive="active-link">Acne Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-berry-blast"
                          routerlinkactive="active-link">DMC-Berry Blast<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-acne-zap-laser"
                          routerlinkactive="active-link">DMC-Acne ZAP Laser<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/diamond-polishing"
                          routerlinkactive="active-link" routerlinkactive="active-link">Diamond Polishing</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-pimple"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-scars"
                        routerlinkactive="active-link">Acne Scars <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-fraxogenus-fractional-co2"
                          routerlinkactive="active-link">DMC-Fraxogenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/tca-cross"
                          routerlinkactive="active-link">TCA Cross</a></li>
               <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/tixel"
                   routerlinkactive="active-link">Tixel</a></li>
               <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-pixigenus-laser"
                   routerLinkActive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
               <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-neoclone-treatment"
                   routerLinkActive="active-link">DMC-Neoclone<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/mnrf-micro-needling-radio-frequency"
                          routerlinkactive="active-link">MNRF</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-scars"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/pigmentation"
                        routerlinkactive="active-link">Pigmentation <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/instaglam-treatment"
                          routerlinkactive="active-link">DMC-Instaglam<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-instaglam-4d"
                          routerlinkactive="active-link">DMC-Instaglam 4D<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-magic-blend-treatment"
                          routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-martini"
                          routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-magic-blend-2-0"
                          routerlinkactive="active-link">DMC-Magic Blend 2.0<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-revivre-plus"
                          routerlinkactive="active-link">DMC-Revivre Plus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/pigmentation"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/skin-glow"
                        routerlinkactive="active-link">Skin Glow <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/miracle-infusum"
                          routerlinkactive="active-link">DMC-Miracle Infusum<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/photofacial"
                          routerlinkactive="active-link">Photofacial</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/4d-photofacial"
                          routerlinkactive="active-link">DMC-4D Photofacial<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/crystal-glaze"
                          routerlinkactive="active-link">DMC-Crystal Glaze<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hydrafacial-treatment"
                          routerlinkactive="active-link">Hydrafacial MD<sup>TM</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-get-set-glow-medifacial"
                          routerlinkactive="active-link">DMC-Get Set Glow<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/skin-glow"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>

                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/hairfall"
                        routerlinkactive="active-link">Hairfall/Hair Loss <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hair-transplant"
                          routerlinkactive="active-link">Hair Transplant</a> <a href="https://www.dmctrichology.com/" class="badge bg-orange blink" target="_blank">DMC-Trichology<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hair-growth-promoters"
                          routerlinkactive="active-link">DMC-Hair Growth Promoter<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/mesotherapy"
                          routerlinkactive="active-link">Mesotherapy</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-root-restore-therapy"
                          routerlinkactive="active-link">DMC-Root
                          Restore Therapy<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/advanced-hgp-2.0"
                          routerlinkactive="active-link">DMC-Advanced HGP 2.0<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dandruff"
                          routerlinkactive="active-link">Dandruff</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/hairfall"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of headerrow -->

              <!-- Start of headerrowsecond -->
              <div class="headerrowsecond">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/unwanted-hair"
                        routerlinkactive="active-link">Unwanted Hair <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/full-body-laser-hair-removal"
                          routerlinkactive="active-link">Full Body Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/bikini-hair-removal"
                          routerlinkactive="active-link">Bikini Hair Removal</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/arm-hair-reduction"
                          routerlinkactive="active-link">Arm Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/laser-beard-shaping"
                          routerlinkactive="active-link">Laser Beard Shaping</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/eyebrow-laser-hair-reduction"
                          routerlinkactive="active-link">Eyebrow
                          Laser Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/laser-for-legs-hair-reduction"
                          routerlinkactive="active-link">Legs
                          Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/titanium-soprano-laser-hair-reduction"
                   routerlinkactive="active-link">Titanium Soprano LHR</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/unwanted-hair"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/anti-aging"
                        routerlinkactive="active-link">Anti-Aging <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/aptos-thread-lift-in-delhi" class="badge bg-orange blink" routerlinkactive="active-link">Aptos Thread Lift</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/age-erase"
                          routerlinkactive="active-link">DMC-Age Erase<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dart"
                          routerlinkactive="active-link">DART(DNA Repair Technique)</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/vampire-facial"
                          routerlinkactive="active-link">Vampire Facial</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/neoclone-treatment">DMC-Neoclone<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hifu-treatment">HIFU
                          Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/grid-fractional-radiofrequency">Grid Fractional Radiofrequency</a>
                      </li>
                      <!-- <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/profhilo-treatment"
                          routerlinkactive="active-link">Profhilo Treatment</a></li> -->
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/anti-aging"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/stretch-marks"
                        routerlinkactive="active-link">Stretch Marks <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-pixigenus"
                          routerlinkactive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-fraxogenus"
                          routerlinkactive="active-link">DMC-Fraxogenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/stretch-marks"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()"
                        routerLink="/treatments/non-surgical-facial-enhancement"
                        routerlinkactive="active-link">Non-Surgical Facial Enhancement <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/nose-enhancement"
                          routerlinkactive="active-link">Nose Enhancement</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/lip-augmentation"
                          routerlinkactive="active-link">Lip Augmentation</a></li>
                          <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/neck-rejuvenation"
                          routerlinkactive="active-link">Neck Rejuvenation</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/treatments/non-surgical-facial-enhancement" routerlinkactive="active-link"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-circles"
                        routerlinkactive="active-link">Dark Circles <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/pixel"
                          routerlinkactive="active-link">Pixel</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/fillers"
                          routerlinkactive="active-link">Fillers</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-circles"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-lips"
                        routerlinkactive="active-link">Dark Lips <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/pixel-perfect"
                          routerlinkactive="active-link">Pixel Perfect</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/fillers-treatment-for-dark-lips"
                          routerlinkactive="active-link">Fillers</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hydrafacial-lip-perk"
                          routerlinkactive="active-link">Hydrafacial Lip Perk</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-lips"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/iv-infusion"
                        routerlinkactive="active-link">IV infusions <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/power-booster"
                          routerlinkactive="active-link">Power Booster</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/vita-zit"
                          routerlinkactive="active-link">Vita Zit</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/iv-infusion"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/body-contouring"
                        routerlinkactive="active-link">Body Contouring <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/freckles"
                        routerlinkactive="active-link">Freckles <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                        <ul>
                           <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/alma-q-switched-laser"
                   routerlinkactive="active-link">ALMA-Q Switched Laser</a></li>
                        </ul>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/tattoo-removal"
                        routerlinkactive="active-link">Tattoo Removal <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                  </div>
                </div>
              </div>
              <!-- // End of headerrowsecond -->
            </div>
            <!-- // End of tab1 -->

            <div id="tab2" class="tab-pane tab-box">
              <!-- Start of tab2 -->
              <div class="row">
                <div class="col-md-4 col-12">
                  <div class="menubridal"><img src="assets/images/mc01.webp" loading="lazy" class="img-fluid"
                      width="700" height="270" alt="Condition" /></div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="menubridal"><img src="assets/images/mc02.webp" loading="lazy" class="img-fluid"
                      width="700" height="270" alt="Condition" /></div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="menubridal"><img src="assets/images/mc03.webp" loading="lazy" class="img-fluid"
                      width="700" height="270" alt="Condition" /></div>
                </div>

                <div class="col-md-4 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/medical">Medical<i
                          class="fa fa-angle-right"></i></a></div>
                    <div class="colditionleft">
                      <ul>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/atopic-dermatitis"
                            routerlinkactive="active-link">Atopic Dermatitis</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/contact-dermatitis"
                            routerlinkactive="active-link">Contact Dermatitis</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/psoriasis"
                            routerlinkactive="active-link">Psoriasis</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/molluscum-contagiosum"
                            routerlinkactive="active-link">Molluscum Contagiosum</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/corn-and-callus"
                            routerlinkactive="active-link">Corn and Callus</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/ingrown-toenails"
                            routerlinkactive="active-link">Ingrown toenails</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/fungal-infections"
                            routerlinkactive="active-link">Fungal Infection</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/warts"
                            routerlinkactive="active-link">Warts</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/medical"><i
                              class="fa fa-angle-double-right"></i> View All</a></li>
                      </ul>
                    </div>

                    <div class="colditionright">
                      <ul>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acanthosis-nigricans"
                            routerlinkactive="active-link">Acanthosis Nigricans</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/rosacea-treatment"
                            routerlinkactive="active-link">Rosacea</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/vitiligo-treatment"
                            routerlinkactive="active-link">Vitiligo</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dpn"
                            routerlinkactive="active-link">DPN</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/skin-growth"
                            routerlinkactive="active-link">Skin Growth</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/nail-avulsion"
                            routerlinkactive="active-link">Nail avulsion</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne"
                            routerlinkactive="active-link">Acne Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/pcod"
                            routerlinkactive="active-link">PCOD</a> </li>
                             <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/lichen-planus"
                            routerlinkactive="active-link">Lichen Planus</a> </li> 
                      </ul>
                    </div>

                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/aesthetics">Aesthetics <i
                          class="fa fa-angle-right"></i></a></div>
                    <div class="colditionleft">
                      <ul>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne"
                            routerlinkactive="active-link">Acne Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/open-pores" routerlinkactive="active-link">Open Pores</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne-scar-treatment">Acne Scar
                            Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/melasma-treatment">Melasma
                            Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()"
                            routerLink="/pigmentation-treatment">Pigmentation
                            Treatment</a></li>
                             <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/wrinkle-removal"
                            routerlinkactive="active-link">Wrinkles Removal</a></li>
                            <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/under-eye-bags"
                            routerlinkactive="active-link">Under Eye Bags</a></li> 
                            <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Freckles
                            Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/aesthetics"><i
                              class="fa fa-angle-double-right"></i> View All</a></li>
                      </ul>
                    </div>

                    <div class="colditionright">
                      <ul>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/sagging-skin">Sagging Skin</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/tattoo-removal" routerlinkactive="active-link">Tattoo Removal</a></li>
                         <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/fine-lines-treatment-in-delhi">Fine Lines Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dark-circles-treatment-in-delhi"
                            routerlinkactive="active-link">Dark Circles Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                            routerlinkactive="active-link">Open Pores Treatment</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                            routerlinkactive="active-link">Stretch Mark Removal</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                            routerlinkactive="active-link">Body Hair</a></li>
                        <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                            routerlinkactive="active-link">Dark Lips</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/hair">Hair <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hair-fall-in-men"
                          routerlinkactive="active-link">Hairfall in Men</a></li>
                          <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hairfall-in-women">Hairfall in
                          Women</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/premature-greying"
                          routerlinkactive="active-link">Premature Greying</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/alopecia-areata"
                          routerlinkactive="active-link">Alopecia Areata</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dandruff">Dandruff</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/scarring-alopecia">Scarring
                          Alopecia</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/conditions/hair"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of tab2 -->
            </div>

            <div id="tab3" class="tab-pane tab-box">
              <!-- Start of tab3 -->
              <div class="row">
                <div class="col-lg-3 col-md-3 col-12">
                  <div class="menubridal"><img src="assets/images/ex01.webp" loading="lazy" class="img-fluid" width="700" height="343" alt="Exclusive Treatment" /></div>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <div class="menubridal"><img src="assets/images/ex02.webp" loading="lazy" class="img-fluid" width="700" height="343" alt="Exclusive Treatment" /></div>
                </div>
                <div class="col-lg-3 col-md-3 col-12">
                  <div class="menubridal"><img src="assets/images/ex03.webp" loading="lazy" class="img-fluid" width="700" height="343" alt="Exclusive Treatment" /></div>
                </div>

                <div class="col-lg-3 col-md-3 col-12">
                  <div class="menubridal"><img src="assets/images/ex04.webp" loading="lazy" class="img-fluid" width="700" height="343" alt="Exclusive Treatment" /></div>
                </div>

                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/skin-glow"
                        routerlinkactive="active-link">Skin Glow <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/crystal-glaze"
                          routerlinkactive="active-link">DMC-Crystal
                          Glaze<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/miracle-infusum"
                          routerlinkactive="active-link">DMC-Miracle
                          Infusum<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/4d-photofacial">DMC-4D
                          Photofacial<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/hydrafacial-treatment">Hydrafacial
                          MD<sup>TM</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-mesoglow">DMC-Mesoglow<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/photofacial">Photofacial</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Glam
                          Resurge</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Body
                          Polishing</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/pigmentation"
                        routerlinkactive="active-link">Pigmentation
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/instaglam-treatment">DMC-Instaglam<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/instaglam-treatment"
                          routerlinkactive="active-link">DMC-Instaglam
                          4D<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-magic-blend-treatment"
                          routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-martini"
                          routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-revivre-plus">DMC-Revivre
                          Plus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/dmc-divagenesis">DMC-DivaGenesis<sup>&#174;</sup></a></li>
                          <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-melablast">DMC-Melablast<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Laser
                          Facials</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/anti-aging"
                        routerlinkactive="active-link">Anti-Aging <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/age-erase"
                          routerlinkactive="active-link">DMC-Age Erase<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/neoclone-treatment"
                          routerlinkactive="active-link">DMC-Neoclone<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-blood-mary-peel"
                          routerlinkactive="active-link">DMC-Bloody Mary Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/anti-aging"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-pimple">Acne/Pimple
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>

                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/acne-check-peel"
                          routerlinkactive="active-link">DMC-Acne Check Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-black-magic-peel"
                          routerlinkactive="active-link">DMC-Black Magic Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dmc-berry-blast"
                          routerlinkactive="active-link">DMC-Berry Blast<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/acne-pimple"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Permanent Mark
                        up <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">Permanent Mark up</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/iv-infusion">IV Infusion <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/power-booster"
                          routerlinkactive="active-link">Power Booster</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/iv-infusion"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-lips">Dark Lips <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">Lip Pigmentation</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/treatments/dark-lips"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of tab3 -->
            </div>

            <div id="tab4" class="tab-pane tab-box">

              <!-- Start of tab4 -->
              <div class="row">
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-about01.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Dadu Medical Centre" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/about-clinic"
                        routerlinkactive="active-link">Dadu Medical Centre <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/about-clinic"
                          routerlinkactive="active-link">About Clinic</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/gallery"
                          routerlinkactive="active-link">Clinic Gallery</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Offers</a>
                      </li>
                      <li><a href="https://www.lanternmeds.com/" target="_blank">Pharmacy (Local Pharmacy)</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dadu-medical-academy">Dadu Medical Academy</a></li>
                      <li><a href="https://www.dadumedicalcentre.com/dmcbodylounge" target="_blank">DMC Body Lounge<sup>&#174;</sup></a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-about02.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Dadu Medical Centre" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/our-doctors">Our Doctors <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dr-nivedita-dadu"
                          routerlinkactive="active-link">Dr. Nivedita Dadu</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/dr-nandini-dadu"
                          routerlinkactive="active-link">Dr. Nandini Dadu</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-about04.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Media" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/press-media">Media <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/press-media">Press & Media</a>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-about03.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Our Technologies" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology">Our Technologies <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/soprano-titanium">Soprano
                          Titanium</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/alma-soprano-ice">Alma Soprano
                          ICE</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/general-project-diode">General
                          Project Diode</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/ultracel">Ultracel</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/alma-harmony">Alma
                          Harmony</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/co2-fractional-laser">CO2
                          Fractional</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/hydrafacial-md">Hydrafacial
                          MD<sup>TM</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology/tixel">Tixel</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/technology"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of tab4 -->
            </div>

            <div id="tab5" class="tab-pane tab-box">
              <!-- Start of tab5 -->
              <div class="row">
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-testimonial01.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Written Testimonials" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/testimonials">Written Testimonials
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <p>Read the testimonies from our precious patients to know about their experience at Dadu Medical
                      Centre. Skin & hair problems can be treated with the right guidance and treatment. Check out what
                      our patients have
                      to say about the treatments they availed at Dadu Medical Centre.</p>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-testimonial02.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Patient Stories" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/patient-stories">Patient Stories <i
                          class="fa fa-angle-right"></i></a></div>
                    <p>Skin is the largest part of our body and demands constant care. Similarly, hair is an important
                      part of one's personality, for both men and women. Watch these videos to know the journey of our
                      previous patients
                      and how we helped them to overcome their skin and hair problems.</p>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-testimonial03.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Cards From
                        Patients" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/cards-from-patients">Cards From
                        Patients <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <p>Hand-written notes are love! Check out this gallery where you can see some hand-written notes
                      from our previous patients. It is always so wonderful to receive feedback for the services we
                      provide at Dadu Medical
                      Centre as it helps us to constantly keep getting better at it.</p>
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="headertav_column">
                    <img src="assets/images/menu-testimonial04.webp" loading="lazy" class="img-fluid" width="700" height="347"
                      alt="Leave Feedback" />
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/leave-feedback">Leave Feedback <i
                          class="fa fa-angle-right"></i></a></div>
                    <p>Here's a quick feedback form that you can fill up to let us know about your experience and your
                      feedback on the services availed at Dadu Medical Centre. Your feedback motivates, guides and helps
                      us to serve you
                      better each day. Look forward to receiving a note from you!</p>
                  </div>
                </div>
              </div>
              <!-- // End of tab5 -->
            </div>

            <div id="tab6" class="tab-pane tab-box">
              <!-- Start of headerrow -->
              <div class="headerrow">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/acne-pimple">Acne / Pimple
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/acne-treatment-results">Acne Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="before-after/acne-zap-results">DMC-Acne
                          ZAP Laser<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="before-after/acne-check-peel-results">DMC-Acne Check Peel<sup>&#174;</sup></a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/back-peel"
                          routerlinkactive="active-link">Back Peel</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/blackheads-and-openpores-results"
                          routerlinkactive="active-link">Blackheads And Openpores</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Acne Check treatment<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/acne-pimple"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/acne-scars">Acne Scars <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/acne-scar-treatment">Acne Scar Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/acne-scar-laser-treatment-results">Acne Scar Laser Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/mnrf-results">MNRF</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/tixel-results">Tixel</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">TCA Cross</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DMC-
                          Fraxogenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/acne-scars"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/pigmentation">Pigmentation
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/pigmentation-treatment">Pigmentation Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/face-laser-toning">Face Laser Toning</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/chemical-burn">Chemical Burn</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/dark-underarms">Dark
                          Underarms</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/melasma"
                          routerlinkactive="active-link">Melasma</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/magic-blend">DMC-Magic
                          Blend<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/pigmentation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Skin Glow <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DMC-Miracle
                          Infusum<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">
                          Hydrafacial MD<sup>TM</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DMC-Crystal
                          Glaze<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DMC-365
                          Oxyboost<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Glam
                          Resurge</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">Skin Boosters</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>

                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/hairfall">Hairfall/Hair Loss
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/androgenetic-alopecia">Androgenetic Alopecia</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/hair-transplant"
                          routerlinkactive="active-link">Hair Transplant</a> <a href="https://www.dmctrichology.com/" class="badge bg-orange blink" target="_blank">DMC-Trichology<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/root-restore-therapy">DMC-Root Restore Therapy<sup>&#174;</sup></a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/male-pattern-baldness" routerLinkActive="active-link">Male Pattern
                          Baldness</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/hgp"
                          routerLinkActive="active-link">DMC-HGP<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/request-consultation">Mesotherapy</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/hairfall"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of headerrow -->

              <!-- Start of headerrowsecond -->
              <div class="headerrowsecond">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/unwanted-hair">Unwanted Hair
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/facial-hair-reduction">Facial Hair Removal</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/laser-beard-shaping">Laser Beard Shaping</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/eyebrow-laser-hair-reduction">Eyebrow Laser Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/laser-hair-reduction">Laser Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/upper-lip-laser-hair-reduction" routerlinkactive="active-link">Upper
                          Lip Laser Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Full Body
                          Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/unwanted-hair"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/anti-aging">Anti-Aging <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/face-lift">Face Lift</a></li>
                      <li><a routerLink="/before-after/fillers" class="hamburgermenu" (click)="menuclick()" routerlinkactive="active-link">Fillers</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/thread-lift">Thread Lift</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DMC-Insta Regen<sup>&#174;</sup></a></li>
                      <!-- <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Tixel</a></li> -->
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Anti-Wrinkle Injection</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation" routerlinkactive="active-link">Vampire Facial</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/anti-aging"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Stretch Marks
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Tixel</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()"
                        routerLink="/results/non-surgical-facial-enhancement">Non-Surgical Facial Enhancement <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/lip-enhancement">Lip
                          Enhancement</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Nose
                          Enhancement</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/non-surgical-facial-enhancement"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/dark-circles">Dark Circles
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>

                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/under-eye-fillers"
                          routerlinkactive="active-link">Under Eye Fillers</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/before-after/under-eye-hollowness">Under Eye Hollowness</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/dark-circles"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/dark-lips">Dark Lips <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/dark-lips">Dark Lips</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/lip-booster">Lip Booster</a></li>
                      <!-- <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/fillers">Fillers</a></li> -->
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/dark-lips"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">IV infusions
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/skin-conditions">Skin
                        Conditions <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/freckles">Freckles <i
                          class="fa fa-angle-right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/results/tattoo-removal">Tattoo
                        Removal <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                        routerlinkactive="active-link">Body Contouring <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                  </div>
                </div>
              </div>
              <!-- // End of headerrowsecond -->
            </div>

            <div id="tab7" class="tab-pane tab-box">
              <!-- Start of headerrow -->
              <div class="headerrow">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/acne-pimple"
                        routerlinkactive="active-link">Acne / Pimple <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/acne-treatment"
                          routerlinkactive="active-link">Acne Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/acne-check-peel"
                          routerlinkactive="active-link">DMC-Acne Check Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-acne-zap-laser"
                          routerlinkactive="active-link">DMC-Acne ZAP Laser<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-berry-blast"
                            routerlinkactive="active-link">DMC-Berry Blast<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/blackheads"
                          routerlinkactive="active-link">Blackheads</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Black Magic Peel<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/acne-pimple"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/acne-scars">Acne Scars <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/acne-scar-treatment"
                          routerlinkactive="active-link">Acne Scars Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/tca-cross">TCA Cross</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Fraxogenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">MNRF</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Tixel</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/request-consultation">Subcision</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/acne-scars"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/pigmentation">Pigmentation <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/magic-blend"
                          routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/melasma"
                          routerlinkactive="active-link">Melasma</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dark-underarms"
                          routerlinkactive="active-link">Dark Underarms</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/uneven-skin-tone-treatment"
                          routerlinkactive="active-link">Uneven Skin
                          Tone</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-martini"
                          routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-instaglam"
                          routerlinkactive="active-link">DMC-Instaglam<sup>&#174;</sup></a></li>
                          <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-divagenesis" routerlinkactive="active-link">DMC-Divagenesis<sup>&#174;</sup></a></li>  
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/pigmentation"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/skin-glow">Skin Glow <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/oxy-jet-peel">DMC-365
                          Oxyboost<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/4d-photofacial"
                          routerLinkActive="active-link">DMC-4D Photofacial<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/medi-facials"
                          routerLinkActive="active-link">Medi Facials</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/hydrafacial">Hydrafacial
                          MD<sup>TM</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/skin-boosters">Skin Boosters</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-crystal-glaze">DMC-Crystal
                          Glaze<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/skin-glow"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>

                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/hairfall"
                        routerlinkactive="active-link">Hairfall/Hair Loss <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/hair-transplant"
                          routerlinkactive="active-link">Hair Transplant</a> <a href="https://www.dmctrichology.com/" class="badge bg-orange blink" target="_blank">DMC-Trichology<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/advanced-hgp"
                          routerlinkactive="active-link">DMC-Advanced HGP 2.0<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/mesotherapy"
                          routerlinkactive="active-link">Mesotherapy</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dmc-root-restore-therapy"
                          routerlinkactive="active-link">DMC-Root
                          Restore Therapy<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Advanced HGP<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-HGP<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/hairfall"
                          routerlinkactive="active-link"><i class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- // End of headerrow -->

              <!-- Start of headerrowsecond -->
              <div class="headerrowsecond">
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/unwanted-hair">Unwanted Hair
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/video/unwanted-hair-treatment">Unwanted
                          Hair Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/video/upper-lip-hair-treatment">Upper Lip Hair Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/laser-hair-reduction">Laser
                          Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/laser-beard-shaping">Laser
                          Beard
                          Shaping</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Eyebrow
                          Laser Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Full Body
                          Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerLinkActive="active-link">Arm Hair Reduction</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/unwanted-hair"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/anti-aging">Anti-Aging <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/video/anti-aging-treatment">Anti-Aging Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/hifu">Hifu
                          Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()"
                          routerLink="/video/dermaroller-treatment">Dermaroller Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/before-after/thread-lift">Thread
                        Lift</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">DART</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Anti-Wrinkle
                          Injection</a></li>
                      
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">Vampire Facial</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/anti-aging"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Stretch Marks
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                          routerlinkactive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Tixel</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>

                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Non-Surgical
                        Facial Enhancement <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Lip
                          Enhancement</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Nose
                          Enhancement</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/dark-circles">Dark Circles <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dark-circles-treatment">Dark
                          Circles Treatment</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Peels</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/dark-circles"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/dark-lips">Dark Lips <i
                          class="fa fa-angle-right"></i></a></div>
                    <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/video/dark-lips-treatment">Dark
                          Lips
                          Treatment</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Fillers</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/dark-lips"><i
                            class="fa fa-angle-double-right" aria-label="View All"></i> View All</a></li>
                    </ul>
                  </div>
                </div>
                <div class="headercolumn">
                  <div class="headertav_column">
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">IV infusions
                        <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <!-- <ul>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Vita
                          Radiance</a></li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">HG Boost</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/vita-zit">Vita Zit</a>
                      </li>
                      <li><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"><i
                            class="fa fa-angle-double-right"></i> View All</a></li>
                    </ul> -->
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/skin-conditions">Skin
                        Conditions <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/tattoo-removal">Tattoo
                        Removal <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/other-videos">Other Videos <i
                          class="fa fa-angle-right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/videos/exclusive-videos">Exclusive
                        Videos <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation"
                        routerlinkactive="active-link">Body Contouring <i class="fa fa-angle-right" aria-label="right"></i></a></div>
                    <div class="mnheading"><a class="hamburgermenu" (click)="menuclick()" routerLink="/request-consultation">Freckles
                        <i class="fa fa-angle-right" aria-label="right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- // End of headerrowsecond -->
            </div>
          </div>
          <!-- End of Tab menu -->
          <div class="clearfix"></div>
        </nav>
        <div class="clearfix"></div>
      </div>
    </header>
  </div>
  <!-- // End of menu -->

  <!-- Start of desktop Header -->
  <div id="dsdsds" class="desktop_header d-none d-sm-none d-md-none d-lg-block d-xl-block"
    *ngIf="isDesktopDevice || isTablet">
    <div class="container-fluid no-gutters">
      <div class="row">
        <div class="col-lg-12 col-md-12 topline no-gutters">
          <p><img src="assets/images/indiaflag.webp" loading="lazy" width="24" height="16" alt="Indian Flag" /> India's Most Trusted
            Skin, Hair, Body &amp;
            Laser
            Clinic.</p>
          <div class="topalignright">
            <!--<a routerLink="/request-consultation" class="hamburgermenu offerstab blink" (click)="logoclick()">Offers</a>--> 
            <a routerLink="/dadu-medical-academy" class="hamburgermenu offerstab blink" (click)="logoclick()">Dadu Medical Academy</a>
            <a routerLink="/influencer-testimonials" class="offerstab offerstab blink">Influencers</a> 
          </div>
       
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="headercenter">
            <div class="logo">
              <a class="hamburgermenu" (click)="logoclick()" routerLink="/">
                <img src="assets/images/home-logo.webp" class="blogo img-fluid" loading="lazy" width="121" height="111" alt="Dadumedicalcentre" />
                <img src="assets/images/inner-logo.webp" class="wlogo img-fluid" loading="lazy" width="121" height="111" alt="Dadumedicalcentre" />
              </a>
            </div>
            <div class="topbarlist">
              <ul>
                <li>
                  <a href="tel:+919810939319"> +91-981-093-9319</a>, <a href="tel:+919667721501">+91-966-772-1501</a>
                </li>
                <li>
                  <a class="buttonappiontment hamburgermenu" (click)="logoclick()" routerLink="/request-consultation"
                    routerLinkActive="active-link">
                    REQUEST A CONSULTATION <i class="fa fa-angle-right" aria-label="righticon"></i></a>
                </li>
              </ul>
            </div>
            <!-- Start of button -->
            <button class="hamburger hamburger--boring" type="button" id="hamburger-id" (click)="menuclick()">
              <span class="hamburger-label">Menu</span>
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
            <!-- // End of button -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- // End of Desktop Header -->

  <!-- Start of mobile header -->
  <div class="mobile_banner d-block d-sm-block d-md-block d-lg-none d-xl-none">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12" (click)="mobilesec()">
          <div class="mobile_top">
            <p><img src="assets/images/indiaflag.webp" loading="lazy" width="24" height="16" alt="Indian Flag" /> India's Most Trusted Skin, Hair, Body &amp; Laser Clinic.</p>
            <ul>
              <li>
                <a href="tel:+919810939319"><i class="fa fa-phone" aria-label="Phone"></i> +91-981-093-9319</a>,
              </li>
              <li><a href="tel:+919667721501">+91-966-772-1501</a></li>
            </ul>
          </div>
        </div>
        <div class="col-3 text-center" (click)="mobilesec()">
          <div class="mmobile_logo">
            <a class="hamburgermenu" (click)="menuclick()" routerLink="/" routerlinkactive="active-link">
              <img src="assets/images/home-logo.webp" class="img-fluid" loading="lazy" width="121" height="111" alt="Dadumedicalcentre" />
            </a>
          </div>
        </div>
        <div class="col-9">
          <a class="mrequest" routerLink="/request-consultation">Request A
            Consultation</a>
          <!-- Start of sidebar menu -->
          <app-header-mobile-nav></app-header-mobile-nav>
          <!-- // End of sidebar menu -->
        </div>
      </div>
    </div>
  </div>
  <!-- // End of mobile header -->
</header>
<!-- // End of Main Header -->
<!-- </div> -->