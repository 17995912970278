<section class="thankyoupage" [defaultImage]="defaultImage" [lazyLoad]="image144">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-12">
        <div class="thankscontainer">
          <i class="fa fa-tick"></i>
          <h1>4<span>0</span>4</h1>
          <h2>Oops! Page Not Found</h2>
          <p>We are sorry, but the page you requested was not found!</p>
          <a routerLink="/" class="yellowbtn">Back To Homepage</a>
        </div>
      </div>
    </div>
  </div>
</section>
