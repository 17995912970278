 <!-- Start of sidebar menu -->
 <div class="mobile_navigation">
  <nav>
    <ul class="pushNav js-topPushNav">
      <li class="closeLevel js-closeLevelTop hdg"><i class="fa fa-close" aria-label="close"></i> Close</li>
      <li><a class="mobilemenu" (click)="menuclick(); closePushNav()" routerLink="/" routerLinkActive="active-link">Home</a></li>

      <!-- Begin section 1 -->
      <li>
        <div class="openLevel js-openLevel">Treatments <i class="fa fa-chevron-right" aria-label="righticon"></i></div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg"><i class="fa fa-chevron-left" aria-label="lefticon"></i> Go Back</li>
          <li>
            <div class="openLevel js-openLevel">Acne / Pimples <i class="fa fa-chevron-right" aria-label="righticon"></i></div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg"><i class="fa fa-chevron-left" aria-label="lefticon"></i>Go Back</li>

              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne-check-peel" routerLinkActive="active-link">DMC-Acne Check Peel<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-black-magic-peel" routerlinkactive="active-link">DMC-Black Magic Peel<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne" routerlinkactive="active-link">Acne
                  Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-berry-blast" routerlinkactive="active-link">DMC-Berry Blast<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-acne-zap-laser"
                  routerlinkactive="active-link">DMC-Acne ZAP Laser<sup>&#174;</sup></a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/mnrf-micro-needling-radio-frequency"
                  routerlinkactive="active-link">MNRF</a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/diamond-polishing"
                  routerLinkActive="active-link">Diamond Polishing</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">DMC-Acne Erase<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">DMC-Acne Check treatment<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">Oil Cease</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Acne Scars
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-fraxogenus-fractional-co2"
                  routerlinkactive="active-link">DMC-
                  Fraxogenus (Fractional Co2)<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/tca-cross"
                  routerLinkActive="active-link">TCA Cross</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/tixel"
                  routerlinkactive="active-link">Tixel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-pixigenus-laser"
                  routerLinkActive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-neoclone-treatment"
                  routerLinkActive="active-link">DMC-Neoclone<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/subcision"
                  routerLinkActive="active-link">Subcision</a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/plasma-induction-therapy"
                  routerLinkActive="active-link">Plasma Induction Therapy</a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/collagen-induction-therapy"
                  routerLinkActive="active-link">Collagen Induction Therapy</a></li>     
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">MNR</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">Meso BTX</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Pigmentation
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/instaglam-treatment" routerLinkActive="active-link">DMC-Instaglam<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-instaglam-4d" routerLinkActive="active-link">DMC-Instaglam 4D<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-magic-blend-treatment" routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-martini" routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-revivre-plus" routerlinkactive="active-link">DMC-Revivre Plus<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-hydraglaze" routerlinkactive="active-link">DMC-Hydraglaze<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-magic-blend-2-0" routerlinkactive="active-link">DMC-Magic Blend 2.0<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-divagenesis" routerLinkActive="active-link">DMC-DivaGenesis<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/chemical-peel" routerLinkActive="active-link">Chemical Peel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-melablast" routerLinkActive="active-link">DMC-Melablast<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-intima-light" routerLinkActive="active-link">DMC Intima Light<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Melabrite</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Skin Glow
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/miracle-infusum"
                  routerlinkactive="active-link">DMC-Miracle Infusum<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/photofacial"
                  routerlinkactive="active-link">Photofacial</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/4d-photofacial"
                  routerLinkActive="active-link">DMC-4D Photofacial<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/crystal-glaze"
                  routerlinkactive="active-link">DMC-Crystal Glaze<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hydrafacial-treatment"
                  routerlinkactive="active-link">Hydrafacial MD<sup>TM</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-get-set-glow-medifacial"
                  routerlinkactive="active-link">DMC-Get Set Glow<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-365-oxyboost"
                  routerLinkActive="active-link">DMC-365 Oxyboost<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-mesoglow"
                  routerLinkActive="active-link">DMC-Mesoglow<sup>&#174;</sup></a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fire-and-ice-facial"
                  routerLinkActive="active-link">Fire & Ice Facial</a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-bling-shots"
                  routerLinkActive="active-link">DMC-Bling Shots<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">Glam Resurge</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">Skin Boosters</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Hairfall / Hair Loss
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hair-transplant"
                  routerLinkActive="active-link">Hair Transplant</a> <a href="https://www.dmctrichology.com/" class="bg-orange blink" target="_blank">DMC-Trichology<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hair-growth-promoters"
                  routerLinkActive="active-link">DMC-Hair Growth Promoter<sup>&#174;</sup></a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-advanced-hgp"
                  routerlinkactive="active-link">DMC-Advanced HGP<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/advanced-hgp-2.0"
                  routerlinkactive="active-link">DMC-Advanced HGP 2.0<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-root-restore-therapy"
                  routerLinkActive="active-link">DMC-Root Restore Therapy<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/mesotherapy"
                  routerLinkActive="active-link">Mesotherapy</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dandruff"
                  routerlinkactive="active-link">Dandruff</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-hg-boost"
                  routerlinkactive="active-link">DMC-HG Boost<sup>&#174;</sup></a></li>  
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Unwanted Hair
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/full-body-laser-hair-removal" routerLinkActive="active-link">Full Body Hair Reduction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/bikini-hair-removal" routerlinkactive="active-link">Bikini Hair Removal</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/arm-hair-reduction" routerLinkActive="active-link">Arm Hair Reduction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/laser-beard-shaping" routerlinkactive="active-link">Laser Beard Shaping</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/eyebrow-laser-hair-reduction" routerlinkactive="active-link">Eyebrow Laser Hair Reduction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/laser-for-legs-hair-reduction" routerLinkActive="active-link">Legs Hair Reduction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/titanium-soprano-laser-hair-reduction" routerlinkactive="active-link">Titanium Soprano LHR</a></li>  
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/facial-hair-removal" routerlinkactive="active-link">Facial Hair Reduction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/laser-hair-removal" routerlinkactive="active-link">Laser Hair Reduction</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Anti-Aging
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/aptos-thread-lift-in-delhi" routerLinkActive="active-link">Aptos Thread Lift</a><a class="hamburgermenu" (click)="menuclick(); closePushNav()" class="bg-orange blink" routerLink="/aptos-thread-lift-in-delhi" routerLinkActive="active-link">Aptos Thread Lift</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/age-erase" routerLinkActive="active-link">DMC-Age Erase<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dart" routerlinkactive="active-link">DART(DNA Repair Technique)</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/vampire-facial" routerLinkActive="active-link">Vampire Facial</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/neoclone-treatment" routerLinkActive="active-link">DMC-Neoclone<sup>&#174;</sup> Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hifu-treatment" routerLinkActive="active-link">HIFU</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/grid-fractional-radiofrequency" routerLinkActive="active-link"> Grid Fractional Radiofrequency(GFR)</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/profhilo-treatment" routerlinkactive="active-link">Profhilo Treatment</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fillers-treatment" routerLinkActive="active-link">Fillers</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/thread-lift-treatment" routerLinkActive="active-link">Thread Lift</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/collagen-induction-therapy-anti-aging" routerLinkActive="active-link">Collagen Induction Therapy</a></li>  
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-insta-regan" routerlinkactive="active-link">DMC-Insta Regen<sup>&#174;</sup></a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-blood-mary-peel" routerLinkActive="active-link">DMC-Bloody Mary Peel<sup>&#174;</sup></a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/superficial-skin-rejuvenation" routerLinkActive="active-link">Superficial Skin Rejuvenation</a></li>
             <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fine-lines-treatment-in-delhi" routerLinkActive="active-link">Fine Lines Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Tixel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Anti-Wrinkle Injection</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">DMC-Age Reserve<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Neck Rejuvenation</a></li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Stretch Marks
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-pixigenus"
                  routerlinkactive="active-link">DMC-Pixigenus<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-fraxogenus"
                  routerLinkActive="active-link">DMC- Fraxogenus (Fractional Co2)<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Tixel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">MNRF</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerLinkActive="active-link">DMC-Neoclone<sup>&#174;</sup></a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Non-Surgical
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/nose-enhancement" routerlinkactive="active-link">Nose Enhancement</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-augmentation" routerlinkactive="active-link">Lip Augmentation</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/neck-rejuvenation" routerLinkActive="active-link">Neck rejuvenation</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/chin-enhancement" routerlinkactive="active-link">Chin Enhancement</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/eyebrow-lift" routerLinkActive="active-link">Eyebrow Lift</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/eyelash-lifting" routerLinkActive="active-link">Eyelash Lifting</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/permanent-makeup-in-delhi" routerLinkActive="active-link">Permanent Makeup</a></li> 
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Cheek Enhancement</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Forehead Lift</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Jawline Enhancement</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Undereye Bags Correction</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerLinkActive="active-link">Eye Last Lifting</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Body Contouring
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hypersculpt" routerlinkactive="active-link">HyperSculpt<sup>TM</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-inch-defy" routerlinkactive="active-link">DMC-Inch Defy<sup>&#174;</sup></a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Dark Circles
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fillers" routerlinkactive="active-link">Fillers</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/pixel" routerlinkactive="active-link">Pixel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hydrafacial-eye-perk" routerlinkactive="active-link">Hydrafacial Eye Perk</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/booster" routerlinkactive="active-link">Booster/ Filler Dark Circle</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dark-circles-dmc-martini" routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dark-circles-treatment-in-delhi" routerlinkactive="active-link">Dark Circles Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Undereye Growth Factor</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Peels</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">DMC-Instaglam 4D<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">DMC-Magic Blend 2.0<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Dark Lips
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/pixel-perfect"
                  routerlinkactive="active-link">Pixel Perfect</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hydrafacial-lip-perk"
                  routerlinkactive="active-link">Hydrafacial Lip Perk</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-martini" routerlinkactive="active-link">Lip Martini</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-booster" routerlinkactive="active-link">Lip Booster</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-peel" routerlinkactive="active-link">Lip Peel</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fillers-treatment-for-dark-lips"
                  routerlinkactive="active-link">Fillers</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">DMC-Instaglam 4D<sup>&#174;</sup></a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              IV Infusions
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/power-booster"
                  routerlinkactive="active-link">Power Booster</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Vita Radiance</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">HG Boost</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Vita Zit</a></li>
            </ul>
          </li>

          <li>
            <div class="openLevel js-openLevel">
              Body Contouring
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hypersculpt"
                  routerlinkactive="active-link">HyperSculpt<sup>TM</sup></a></li>
            </ul>
          </li>
          <!-- <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/treatments/freckles"
              routerlinkactive="active-link">Freckles</a></li> -->

          <li>
            <div class="openLevel js-openLevel">
              Freckles
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/alma-q-switched-laser"
                  routerlinkactive="active-link">ALMA-Q Switched Laser</a></li>
            </ul>
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/tattoo-removal"
              routerlinkactive="active-link">Tattoo Removal</a></li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
      </li>
      <!-- End section 1 -->

      <!-- Begin section 2 -->
      <li>
        <div class="openLevel js-openLevel">
          Conditions
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li>
            <div class="openLevel js-openLevel">Medical <i class="fa fa-chevron-right" aria-label="righticon"></i></div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg"><i class="fa fa-chevron-left" aria-label="lefticon"></i>Go Back</li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/atopic-dermatitis"
                  routerlinkactive="active-link">Atopic Dermatitis</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/contact-dermatitis"
                  routerlinkactive="active-link">Contact Dermatitis</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/psoriasis"
                  routerlinkactive="active-link">Psoriasis</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/molluscum-contagiosum"
                  routerlinkactive="active-link">Molluscum Contagiosum</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/corn-and-callus"
                  routerlinkactive="active-link">Corn and Callus</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/ingrown-toenails"
                  routerlinkactive="active-link">Ingrown toenails</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fungal-infections"
                  routerlinkactive="active-link">Fungal Infection</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/urticaria"
                  routerlinkactive="active-link">Urticaria</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/warts"
                  routerlinkactive="active-link">Warts</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acanthosis-nigricans"
                  routerlinkactive="active-link">Acanthosis Nigricans</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/rosacea-treatment"
                  routerlinkactive="active-link">Rosacea</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/vitiligo-treatment"
                  routerlinkactive="active-link">Vitiligo</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dpn"
                  routerlinkactive="active-link">DPN</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/skin-growth"
                  routerlinkactive="active-link">Skin Growth</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/nail-avulsion"
                  routerlinkactive="active-link">Nail avulsion</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne" routerlinkactive="active-link">Acne
                  Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/pcod"
                  routerlinkactive="active-link">PCOD</a> </li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/seborrheic-dermatitis"
                  routerlinkactive="active-link">Seborrheic dermatitis</a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/birthmarks-treatment"
                  routerlinkactive="active-link">Birth Marks</a></li>
                   <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lichen-planus"
                  routerlinkactive="active-link">Lichen Planus</a></li>
                   <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/eczema" routerlinkactive="active-link">Eczema</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/sensitive-skin"
                  routerlinkactive="active-link">Sensitive Skin</a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/cyst-removal"
                  routerlinkactive="active-link">Cyst Removal</a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/skin-tags"
                  routerlinkactive="active-link">Skin Tags</a></li>   
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Keloid</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Spider veins</a></li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Aesthetics
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne" routerlinkactive="active-link">Acne
                  Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/open-pores" routerlinkactive="active-link">Open Pores</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne-scar-treatment"
                  routerlinkactive="active-link">Acne Scar Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/under-eye-bags"
                  routerlinkactive="active-link">Under Eye Bags</a></li> 
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/pigmentation-treatment"
                  routerlinkactive="active-link">Pigmentation Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/melasma-treatment"
                  routerlinkactive="active-link">Melasma Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/wrinkle-removal"
                  routerlinkactive="active-link">Wrinkles Removal</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/sagging-skin"
                  routerlinkactive="active-link">Sagging Skin</a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/tattoo-removal"
                  routerlinkactive="active-link">Tattoo Removal</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Freckles Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/fine-lines-treatment-in-delhi"
                  routerlinkactive="active-link">Fine Lines Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dark-circles-treatment-in-delhi"
                  routerlinkactive="active-link">Dark Circles Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Open Pores Treatment</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Stretch Mark Removal</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Body Hair</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Dark Lips</a></li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Hair
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hair-fall-in-men"
                  routerlinkactive="active-link">Hairfall in Men</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hairfall-in-women"
                  routerlinkactive="active-link">Hairfall in Women</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/premature-greying"
                  routerlinkactive="active-link">Premature Greying</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/alopecia-areata"
                  routerlinkactive="active-link">Alopecia Areata</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dandruff"
                  routerlinkactive="active-link">Dandruff</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/scarring-alopecia"
                  routerlinkactive="active-link">Scarring Alopecia</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <!-- End section 2 -->

      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/about-clinic" routerlinkactive="active-link">Dadu
          Medical Centre</a></li>
      <li><a href="https://www.lanternmeds.com/" target="_blank">Pharmacy (Local Pharmacy)</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dadu-medical-academy" routerlinkactive="active-link">Dadu Medical Academy</a></li>
      <li><a href="https://www.dadumedicalcentre.com/dmcbodylounge" target="_blank">DMC Body Lounge<sup>&#174;</sup></a></li>

      <!-- Begin section 4 -->
      <li>
        <div class="openLevel js-openLevel">
          Our Doctors
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dr-nivedita-dadu"
              routerlinkactive="active-link">Dr. Nivedita Dadu</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dr-nandini-dadu"
              routerlinkactive="active-link">Dr. Nandini Dadu</a></li>
        </ul>
      </li>
      <!-- Begin section 4 -->

      <!-- Begin section 5 -->
      <li>
        <div class="openLevel js-openLevel">
          Our Technology
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/soprano-titanium"
              routerlinkactive="active-link">Soprano Titanium</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/alma-soprano-ice"
              routerlinkactive="active-link">Alma Soprano ICE</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/general-project-diode"
              routerlinkactive="active-link">General Project Diode</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/ultracel"
              routerlinkactive="active-link">Ultracel</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/alma-harmony"
              routerlinkactive="active-link">Alma Harmony</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/co2-fractional-laser"
              routerlinkactive="active-link">CO2 Fractional Laser</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/hydrafacial-md"
              routerlinkactive="active-link">Hydrafacial MD<sup>TM</sup></a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/tixel"
              routerlinkactive="active-link">Tixel</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/oxyjet-machine"
              routerlinkactive="active-link">Oxyjet Machine</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/ipl"
              routerlinkactive="active-link">IPL</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/alma-q"
              routerlinkactive="active-link">Alma-Q</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/dermafrac"
              routerlinkactive="active-link">Dermafrac</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/nbuvb"
              routerlinkactive="active-link">NBUVB</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/mesotherapy"
              routerlinkactive="active-link">Mesotherapy</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/technology/mda"
              routerlinkactive="active-link">MDA</a></li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
          <li>&nbsp;</li>
        </ul>
      </li>
      <!-- Begin section 5 -->

      <!-- Begin section 6 -->
      <li>
        <div class="openLevel js-openLevel">
          Exclusive Treatments
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li>
            <div class="openLevel js-openLevel">Skin Glow <i class="fa fa-chevron-right" aria-label="righticon"></i></div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg"><i class="fa fa-chevron-left" aria-label="lefticon"></i>Go Back</li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/crystal-glaze"
                  routerlinkactive="active-link">DMC-Crystal
                  Glaze<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/miracle-infusum"
                  routerlinkactive="active-link">DMC-Miracle
                  Infusum<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/4d-photofacial">DMC-4D
                  Photofacial<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/hydrafacial-treatment"
                  routerlinkactive="active-link">Hydrafacial MD<sup>TM</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-mesoglow"
                  routerlinkactive="active-link">DMC-Mesoglow<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/photofacial"
                  routerlinkactive="active-link">Photofacial</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Glam Resurge</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Body Polishing</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Pigmentation
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()"
                  routerLink="/instaglam-treatment">DMC-Instaglam<sup>&#174;</sup></a>
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-instaglam-4d"
                  routerlinkactive="active-link">DMC-Instaglam
                  4D<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-magic-blend-treatment"
                  routerlinkactive="active-link">DMC-Magic Blend<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-martini"
                  routerlinkactive="active-link">DMC-Martini<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-revivre-plus"
                  routerlinkactive="active-link">DMC-Revivre Plus<sup>&#174;</sup></a></li>
                  <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-divagenesis"
                  routerlinkactive="active-link">DMC-DivaGenesis<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Laser Facials</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Dermalogical Peel</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Anti-Aging
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/age-erase"
                  routerlinkactive="active-link">DMC-Age Erase<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/neoclone-treatment"
                  routerlinkactive="active-link">DMC-Neoclone<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">DMC-Bloody Mary Peel<sup>&#174;</sup></a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Acne/Pimple
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/acne-check-peel"
                  routerlinkactive="active-link">DMC-Acne Check Peel<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-black-magic-peel"
                  routerlinkactive="active-link">DMC-Black Magic Peel<sup>&#174;</sup></a></li>
               <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/dmc-berry-blast"
                         routerlinkactive="active-link">DMC-Berry Blast<sup>&#174;</sup></a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Oil Cease</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Permanent Mark Up
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Permanent Mark Up</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              IV Infusion
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/power-booster"
                  routerlinkactive="active-link">Power Booster</a></li>
            </ul>
          </li>
          <li>
            <div class="openLevel js-openLevel">
              Dark Lips
              <i class="fa fa-chevron-right" aria-label="righticon"></i>
            </div>
            <ul class="pushNav pushNav_level js-pushNavLevel">
              <li class="closeLevel js-closeLevel hdg">
                <i class="fa fa-chevron-left" aria-label="lefticon"></i>
                Go Back
              </li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
                  routerlinkactive="active-link">Lip Pigmentation</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-martini" routerlinkactive="active-link">Lip Martini</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-booster" routerlinkactive="active-link">Lip Booster</a></li>
              <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/lip-peel" routerlinkactive="active-link">Lip Peel</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <!-- End section 6 -->

      <!-- Begin section 7 -->
      <li>
        <div class="openLevel js-openLevel">
          Testimonials
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/testimonials"
              routerLinkActive="active-link">Written Testimonials</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/patient-stories"
              routerLinkActive="active-link">Patient Stories</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/cards-from-patients"
              routerLinkActive="active-link">Cards From Patients</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/leave-feedback"
              routerLinkActive="active-link">Leave Feedback</a></li>
        </ul>
      </li>
      <!-- Begin section 7 -->

      <!-- Begin section 8 -->
      <li>
        <div class="openLevel js-openLevel">
          Results
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/acne-pimple" routerlinkactive="active-link">Acne / Pimples</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/acne-scars" routerlinkactive="active-link">Acne Scars</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/pigmentation" routerlinkactive="active-link">Pigmentation</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Skin Glow</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/hairfall" routerlinkactive="active-link">Hairfall/Hair Loss</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/unwanted-hair" routerlinkactive="active-link">Unwanted Hair</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/anti-aging" routerlinkactive="active-link">Anti-Aging</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Stretch Marks</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/non-surgical-facial-enhancement" routerlinkactive="active-link">Non Surgical Facial Enhancement</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/dark-circles" routerlinkactive="active-link">Dark Circles</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/dark-lips" routerlinkactive="active-link">Dark Lips</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">IV Infusions</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/skin-conditions" routerlinkactive="active-link">Skin Conditions</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/freckles" routerlinkactive="active-link">Freckles</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/results/tattoo-removal" routerlinkactive="active-link">Tattoo Removal</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Body Contouring</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Other Results</a></li>
        </ul>
      </li>
      <!-- Begin section 8 -->

       <!-- Begin section 9 -->
       <li>
        <div class="openLevel js-openLevel">
          Videos
          <i class="fa fa-chevron-right" aria-label="righticon"></i>
        </div>
        <ul class="pushNav pushNav_level js-pushNavLevel">
          <li class="closeLevel js-closeLevel hdg">
            <i class="fa fa-chevron-left" aria-label="lefticon"></i>
            Go Back
          </li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/acne-pimple" routerlinkactive="active-link">Acne / Pimples</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/acne-scars" routerlinkactive="active-link">Acne Scars</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/pigmentation" routerlinkactive="active-link">Pigmentation</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/skin-glow" routerlinkactive="active-link">Skin Glow</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/hairfall" routerlinkactive="active-link">Hairfall/Hair Loss</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/unwanted-hair" routerlinkactive="active-link">Unwanted Hair</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/anti-aging" routerlinkactive="active-link">Anti-Aging</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Stretch Marks</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Non Surgical Facial Enhancement</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/dark-circles" routerlinkactive="active-link">Dark Circles</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/dark-lips" routerlinkactive="active-link">Dark Lips</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">IV Infusions</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/skin-conditions" routerlinkactive="active-link">Skin Conditions</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/tattoo-removal" routerlinkactive="active-link">Tattoo Removal</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/other-videos" routerlinkactive="active-link">Other Videos</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/videos/exclusive-videos" routerlinkactive="active-link">Exclusive Videos</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Body Contouring</a></li>
          <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation" routerlinkactive="active-link">Freckles</a></li>
        </ul>
      </li>
      <!-- Begin section 9 -->

      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/gallery"
          routerlinkactive="active-link">Gallery</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/blogs" routerlinkactive="active-link">Blogs</a>
      </li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/press-media" routerLinkActive="active-link">Press
          Media</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/contact-us"
          routerlinkactive="active-link">Contact Us</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/careers"
          routerlinkactive="active-link">Careers</a></li>
       <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/influencer-testimonials">Influencers</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation">Offers</a></li>
      <li><a class="hamburgermenu" (click)="menuclick(); closePushNav()" routerLink="/request-consultation"
          routerlinkactive="active-link">Request A Consultation</a></li>
      <li>&nbsp;</li>
      <li>&nbsp;</li>
      <li>&nbsp;</li>
      <li>&nbsp;</li>
    </ul>
  </nav>
  <div class="wrappersto">
    <div class="burger js-menuToggle">
      <i class="fa fa-navicon" aria-label="navicon"></i>
    </div>
  </div>
  <span class="screen"></span>
</div>
<!-- // End of sidebar menu -->
