import{ Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import{ HttpClient, HttpClientModule } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class pressmediaapi{
   backendUrl = environment.backendbaseUrl;
    constructor(private httpclient: HttpClient){}
    pressmediacategory(): Observable<any>{
        // url = backendUrl"pressmedia-category";
        return this.httpclient.get( this.backendUrl + "pressmedia-category");
    }

    pressmedialist(name:any): Observable<any>{
        // url = backendUrl"pressmedia-category";
        return this.httpclient.get( this.backendUrl + "pressmedia/"+name);
    }

    pressmediadetails(name:any): Observable<any>{
        // url = backendUrl"pressmedia-category";
        return this.httpclient.get( this.backendUrl + "pressmediadetail/"+name);
    }
} 