export const environment = {
  production: true,
  baseUrl: 'https://www.dadumedicalcentre.com',
  backendbaseUrl: 'https://www.dadumedicalcentre.com:8080/api/',
  backendimageUrl: 'https://www.dadumedicalcentre.com:8080/images/',

  // baseUrl: 'http://localhost:4200',
  // backendbaseUrl: 'http://digilantern.co/dadu-medical-admin/public/api/',
  // backendimageUrl: 'http://digilantern.co/dadu-medical-admin/public/images/',

  // baseUrl: 'https://www.localhost:4200',
  // backendbaseUrl: 'http://localhost:8000/api/',
  // backendimageUrl: 'http://localhost:8000/images/',

  // backendbaseUrl: 'http://192.168.0.93:8084/api/',
  // backendimageUrl: 'http://192.168.0.93:8084/images/',
};
