import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ErrorpageComponent } from './dadu-medical/errorpage/errorpage.component';
import { CustomPreloadingStrategy } from './custom-preloading.strategy';
// import { PaymentSucessfulComponent } from './dmclanding-module/payment-sucessful/payment-sucessful.component';

const routes: Routes = [
  // Redirection service page
  { path: 'treatments/medical', redirectTo:'conditions/medical', pathMatch:'full' , data: { statusCode: 301 } },
  { path: 'treatments/aesthetics', redirectTo:'conditions/aesthetics', pathMatch:'full' , data: { statusCode: 301 } },
  { path: 'treatments/hair', redirectTo:'conditions/hair', pathMatch:'full' , data: { statusCode: 301 } },
  {
    path: '', 
    loadChildren: () =>import('./home-module/home/home-module.module').then(m => m.HomeModuleModule),
    data: { preload: true }
  },
  {
    path: '', 
    loadChildren: () =>import('./dadu-medical/dadu-medical.module').then(m => m.DaduMedicalModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./about-module/about-module.module').then(m => m.AboutModuleModule),
    data: { preload: false }
  }
  ,
  {
    path: '', 
    loadChildren: () =>import('./gallery-module/gallery-module.module').then(m => m.GalleryModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./video-section/video-section.module').then(m => m.VideoSectionModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./result-module/result-module.module').then(m => m.ResultModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./pressmedia-module/press-module.module').then(m => m.PressModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./blog-module/blog-module.module').then(m => m.BlogModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./techonology-module/technology-module.module').then(m => m.TechnologyModuleModule),
    data: { preload: false }
  },

  {
    path: '', 
    loadChildren: () =>import('./offers-module/offers-module.module').then(m => m.OffersModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./influencertab/influencertab.module').then(m => m.InfluencertabModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./career-module/career-module.module').then(m => m.CareerModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./privacy-module/privacy-module.module').then(m => m.PrivacyModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./dmclanding-module/dmclanding-module.module').then(m => m.DmclandingModuleModule),
    data: { preload: false }
  },
  {
    path: '', 
    loadChildren: () =>import('./service-module/service-module.module').then(m => m.ServiceModuleModule),
    data: { preload: true }
  },
  // { path: 'payment/:any', component: PaymentSucessfulComponent },
  { path: '404', component: ErrorpageComponent },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
      preloadingStrategy: CustomPreloadingStrategy,
      // preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy] // Provide the custom strategy
})
export class AppRoutingModule { }
