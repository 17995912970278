// import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { FooterService } from "./footer.service";
// import { isPlatformBrowser } from "@angular/common";
// import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush, 
})

export class FooterComponent implements OnInit {
  defaultImage = 'assets/images/defaultImage.jpeg';
  image82 ='assets/images/footerlogo.webp';
  image83 ='assets/images/dash.webp';
  INNN: string;

  // fullbodychecker: boolean = true;

  constructor(private footerService: FooterService, 
    private cdr: ChangeDetectorRef,
    // private router: Router, 
    // @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.footerService.toggleFooter(true);
    // this.checkUserAgent();
  }

  // checkUserAgent(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const targetUserAgentString = 'moto g power (2022)';
  //     const userAgent = navigator.userAgent;
  //     this.fullbodychecker = userAgent.indexOf(targetUserAgentString) !== -1;
  //     console.log(this.fullbodychecker, "fullbody checker");
  //   }
  // }

  showFooter$ = this.footerService.showFooter$;
  isShow: boolean;
  topPosToStartShowing = 500;

  @HostListener('window:scroll')
  checkScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollPosition >= this.topPosToStartShowing) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.cdr.markForCheck();
  }

  currentYear: number;
  previousYear: number;

  gotoTop() {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.previousYear = this.currentYear - 1;

  }
}