import {ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent {
  title = 'dr-nivedita-dadu';

  constructor() {}

  mobilesec() {
    //  $('.pushNav').removeClass('isOpen');
  }

}