import { Component, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-header-mobile-nav',
  templateUrl: './header-mobile-nav.component.html',
  styleUrls: ['./header-mobile-nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMobileNavComponent implements OnInit {
  
  displayIframe = false;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setupEventListeners();
  }

  public setupEventListeners(): void {
    const menuTrigger = this.elRef.nativeElement.querySelectorAll('.js-menuToggle');
    const topNav = this.elRef.nativeElement.querySelector('.js-topPushNav');
    const openLevel = this.elRef.nativeElement.querySelectorAll('.js-openLevel');
    const closeLevel = this.elRef.nativeElement.querySelectorAll('.js-closeLevel');
    const closeLevelTop = this.elRef.nativeElement.querySelector('.js-closeLevelTop');
    const navLevel = this.elRef.nativeElement.querySelectorAll('.js-pushNavLevel');
    const screen = this.elRef.nativeElement.querySelector('.screen');

    const openPushNav = () => {
      this.renderer.addClass(topNav, 'isOpen');
      this.renderer.addClass(document.body, 'pushNavIsOpen');
    };

    const closePushNav = () => {
      this.renderer.removeClass(topNav, 'isOpen');
      openLevel.forEach(el => this.renderer.removeClass(el, 'isOpen'));
      this.renderer.removeClass(document.body, 'pushNavIsOpen');
    };

    menuTrigger.forEach(trigger =>
      trigger.addEventListener('click', (e: Event) => {
        e.preventDefault();
        if (topNav.classList.contains('isOpen')) {
          closePushNav();
        } else {
          openPushNav();
        }
      })
    );

    openLevel.forEach(level =>
      level.addEventListener('click', () => {
        const nextNavLevel = level.nextElementSibling;
        if (nextNavLevel) {
          this.renderer.addClass(nextNavLevel, 'isOpen');
        }
      })
    );

    closeLevel.forEach(level =>
      level.addEventListener('click', () => {
        const closestNavLevel = level.closest('.js-pushNavLevel');
        if (closestNavLevel) {
          this.renderer.removeClass(closestNavLevel, 'isOpen');
        }
      })
    );

    if (closeLevelTop) {
      closeLevelTop.addEventListener('click', () => closePushNav());
    }

    if (screen) {
      screen.addEventListener('click', () => closePushNav());
    }

      // Listen for clicks outside the menu to close it
      this.listenForOutsideClick(topNav, closePushNav);
  }

  listenForOutsideClick(topNav: HTMLElement, closePushNav: () => void): void {
    this.renderer.listen('document', 'click', (event: Event) => {
      const clickedInside = topNav.contains(event.target as Node) || 
                            this.elRef.nativeElement.contains(event.target as Node);
      if (!clickedInside && topNav.classList.contains('isOpen')) {
        closePushNav();
      }
    });
  }

  public closePushNav(): void {
    const topNav = this.elRef.nativeElement.querySelector('.js-topPushNav');
    this.renderer.removeClass(topNav, 'isOpen');
    this.renderer.removeClass(document.body, 'pushNavIsOpen');

    // Close any open levels
    const openLevel = this.elRef.nativeElement.querySelectorAll('.js-openLevel');
    openLevel.forEach(el => this.renderer.removeClass(el.nextElementSibling, 'isOpen'));
  }

  menuclick(): boolean {
    // alert('Menu item disable');
    const menuToggle = this.elRef.nativeElement.querySelector('#menu-toggle') as HTMLInputElement;
    const pushNav = this.elRef.nativeElement.querySelector('.pushNav');
    const hamburger = this.elRef.nativeElement.querySelector('.hamburger');
    const siteNav = this.elRef.nativeElement.querySelector('#site-nav');
    const masthead = this.elRef.nativeElement.querySelector('#masthead');
    const dsdsds = this.elRef.nativeElement.querySelector('#dsdsds');
    const header = this.elRef.nativeElement.querySelector('header');

    const topNav = this.elRef.nativeElement.querySelector('.js-topPushNav');
    if (topNav && topNav.classList.contains('isOpen')) {
      this.closePushNav(); // Close if open
    }
   
    if (menuToggle) {
      menuToggle.checked = false;
    }
    if (pushNav) {
      if (pushNav.classList.contains('isOpen')) {
        this.renderer.removeClass(pushNav, 'isOpen');
      } else {
        this.renderer.addClass(pushNav, 'isOpen');
      } 
    }
    if (hamburger) {
      if (hamburger.classList.contains('is-active')) {
        this.renderer.removeClass(hamburger, 'is-active');
      } else {
        this.renderer.addClass(hamburger, 'is-active');
      }
    }
    if (siteNav) {
      if (siteNav.classList.contains('is-active')) {
        this.renderer.removeClass(siteNav, 'is-active');
      } else {
        this.renderer.addClass(siteNav, 'is-active');
      }
    }
    if (masthead) {
      if (masthead.classList.contains('is-active')) {
        this.renderer.removeClass(masthead, 'is-active');
      } else {
        this.renderer.addClass(masthead, 'is-active');
      }
    }
    if (dsdsds) {
      if (dsdsds.classList.contains('hdrtgl')) {
        this.renderer.removeClass(dsdsds, 'hdrtgl');
      } else {
        this.renderer.addClass(dsdsds, 'hdrtgl');
      }
    }
    if (header) {
      if (header.classList.contains('scrollbar-top')) {
        this.renderer.removeClass(header, 'scrollbar-top');
      } else {
        this.renderer.addClass(header, 'scrollbar-top');
      }
    }
    if (masthead) {
      masthead.style.display = 'block';
    }

    this.displayIframe = true;
    this.cdr.markForCheck();

    return false;
  }

}
